import * as React from 'react';
import Container from '@mui/material/Container';
import { StaticImage } from 'gatsby-plugin-image';
// import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import produce from 'immer';
// import Link from '../components/Link';
import styled from 'styled-components';
// import { Helmet } from 'react-helmet';
import { graphql, Link as GatsbyLink } from 'gatsby';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { useSnackbar } from 'notistack';

import { useSelector, useDispatch } from 'react-redux';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import TimePicker from '@mui/lab/TimePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Copyright from '../components/Copyright';
import ProTip from '../components/ProTip';
import SEO from '../components/seo';
import { useAppContext } from '../services/context/AppContext';

const StyledLink = styled(Link)`
  && {
    // color: #fff;
    display: block;
  }
`;
// const StyledContainer = styled(Container)`
//   ${({ theme }) => `
//     background-color: ${theme.palette.grey[500]};
//   `}
// `;

const Index = () => {
  // Context example
  const {
    themes,
    themeToUse,
    dynamicTheme,
    setDynamicTheme,
  } = useAppContext();
  // Redux example
  const user = useSelector((state) => (state.user));
  const dispatch = useDispatch();
  console.log('redux.....', user);

  const doUpdateRole = () => {
    // setBaseBackdrop({ open: true, child: null });
    // () => dispatch({ type: 'UPDATE_ROLE', role: 2 }),
    // [dispatch],
    dispatch({ type: 'UPDATE_ROLE', role: 2 });
  };

  const doReset = () => {
    // setBaseBackdrop({ open: false, child: null });
    dispatch({ type: 'RESET' });
  };

  // Snackbar example
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = () => {
    enqueueSnackbar('I love snacks.');
  };

  const handleClickVariant = (variant) => () => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar('This is a success message!', { variant });
  };

  const switchDarkLightMode = () => {
    const newTheme = produce(dynamicTheme, (draft) => {
      const { mode } = draft.palette;
      draft.palette.mode = mode === 'dark' ? 'light' : 'dark';
    });
    setDynamicTheme(newTheme);
  };


  const { t } = useTranslation();
  const [value, setValue] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <Container maxWidth="sm">
      <StaticImage
        src="../assets/images/Lavoic-Logo-light.png"
        // width={300}
        height={20}
        quality={95}
        formats={['auto', 'webp', 'avif']}
        alt="A Gatsby astronaut"
        // style={{ marginBottom: '1.45rem' }}
      />
      <br />
      <span>
        Current Theme from Context:&nbsp;
        {themeToUse}
      </span>
      <br />
      <span>
        Available Themes from Context:&nbsp;
        {themes}
      </span>
      <br />
      <Button variant="contained" color="primary" onClick={switchDarkLightMode}>
        Dark/Light
      </Button>
      <hr />
      <span>
        Current Role ID:&nbsp;
        {user.role}
      </span>
      <br />
      <Button variant="contained" color="primary" onClick={doUpdateRole}>
        Set Redux user to role 2
      </Button>
      <br />
      <Button variant="contained" color="primary" onClick={doReset}>
        Reset User in Redux
      </Button>
      <hr />
      <Button variant="contained" onClick={handleClick}>Show snackbar</Button>
      <br />
      <Button variant="contained" onClick={handleClickVariant('success')}>Show success snackbar</Button>
      <br />
      <SEO title={t('Home')} />
      {/* <Helmet>
        <title>My Title</title>
      </Helmet> */}
      <Box sx={{ my: 4 }}>
        <div>
          <h1><Trans>Welcome</Trans></h1>
          <p><Trans>My name</Trans></p>
          <p>{t('message', { xxx: 'xyx123' })}</p>
        </div>
        <hr />
        <StyledLink to="/about" color="error">
          Go to the about page
        </StyledLink>
        <StyledLink to="/styled-components" color="secondary">
          Go to the styled-components page
        </StyledLink>
        <p>
          <GatsbyLink to="/ignored-page">
            <Trans>Go to ignored page</Trans>
          </GatsbyLink>
        </p>
        <hr />
      </Box>
      <Stack spacing={3}>
        <DesktopDatePicker
          label="Date desktop"
          inputFormat="MM/dd/yyyy"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <MobileDatePicker
          label="Date mobile"
          inputFormat="MM/dd/yyyy"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <TimePicker
          label="Time"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <DateTimePicker
          label="Date&Time picker"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
      <ProTip />
      <Copyright />
    </Container>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Index;
